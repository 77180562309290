import { useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { add_giveaway } from './services/api';
import { Halmet } from './component/helmet/Halmet';




function Give_away() {
    const [isWhite5, setIsWhite5] = useState(true);

    const handleButtonClick5 = () => {
    
        setIsWhite5(!isWhite5);
        console.log("isWhite5",isWhite5)
    };
    const {
        handleSubmit,
        setValues,
        setFieldValue,
        handleBlur,
        values,
        errors,
        touched,
        getFieldProps,
    } = useFormik({
        initialValues: {
            Fullname: "",
            Schoolname: "",
            Email: "",
            Phonenumber: "",
            Position: "",
            ISInform: "Yes",

        },
        validationSchema: Yup.object({
            Fullname: Yup.string().required("Full name is required"),
            Schoolname: Yup.string().required("School name is required"),
            Position: Yup.string().required("Position is required"),
            Email: Yup.string()
                .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    "Invalid email address"
                )
                .required("Email address is required"),
            Phonenumber: Yup.string().matches(/^[0-9]+$/, "Phone number must contain only numbers").required("Phone number is required"),

        }),
        onSubmit: async (values) => {
            const data = {
                fullname: values.Fullname,
                schoolname: values.Schoolname,
                email_id: values.Email,
                phone_number: values.Phonenumber,
                position: values.Position,
                is_newsletter: isWhite5 ? "No" : "Yes",
            }
            const res = await add_giveaway(data)
            if (res.status === 200 || res.status === 201) {
    
                toast.success('Giveaway added successfully!', {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                setTimeout(()=> {
                    window.location.href = "https://www.bebranded.com.au";
                },1000)
               
              } else {
                toast.error(`${res.response.data.massage}`, {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
        

          

            // Check if password meets the strength criteria

        }
    })


    return (
        <div className="App">
            <ToastContainer />
            <Halmet title={"Giveaway | Bebranded"} />
            <div className="containers">
                <div>
                    <img className='header-img' src="./assets/images/BB.png" alt="bebranded" />
                </div>
                <div>
                    <img className='form-img' width={"660px"} src="./assets/images/BB-1.png" alt="" srcSet="" />
                </div>
                <form action="" style={{ backgroundColor: '#122b53' }} >
                    <div className='mb'>  <label htmlFor="">FULL NAME</label>
                        <input type="text" id='Fullname' name='Fullname' {...getFieldProps("Fullname")}
                            value={values.Fullname}
                            onChange={(e) =>
                                setFieldValue("Fullname", e.target.value.trimStart())
                            }
                            onBlur={handleBlur} />
                        {touched.Fullname && errors.Fullname ? (
                            <div className="error_txt  d-flex align-items-center justify-content-center">{errors.Fullname}</div>
                        ) : null}</div>
                    <div className='mb'>  <label htmlFor="">SCHOOL NAME</label>
                        <input type="text" id='Schoolname' name='Schoolname' {...getFieldProps("Schoolname")}
                            value={values.Schoolname}
                            onChange={(e) =>
                                setFieldValue("Schoolname", e.target.value.trimStart())
                            }
                            onBlur={handleBlur} />
                        {touched.Schoolname && errors.Schoolname ? (
                            <div className="error_txt  d-flex align-items-center justify-content-center">{errors.Schoolname}</div>
                        ) : null}</div>
                    <div className='mb'><label htmlFor="">POSITION</label>
                        <input type="text" id='Position' name='Position' {...getFieldProps("Position")}
                            value={values.Position}
                            onChange={(e) =>
                                setFieldValue("Position", e.target.value.trimStart())
                            }
                            onBlur={handleBlur} />
                        {touched.Position && errors.Position ? (
                            <div className="error_txt  d-flex align-items-center justify-content-center">{errors.Position}</div>
                        ) : null}</div>
                    <div className='mb'>  <label htmlFor="">EMAIL ADDRESS</label>
                        <input type="text" id='Email' name='Email' {...getFieldProps("Email")}
                            value={values.Email}
                            onChange={(e) =>
                                setFieldValue("Email", e.target.value.trim())
                            }
                            onBlur={handleBlur} />
                        {touched.Email && errors.Email ? (
                            <div className="error_txt  d-flex align-items-center justify-content-center">{errors.Email}</div>
                        ) : null}</div>
                    <div className='mb'> <label htmlFor="">PHONE NUMBER</label>
                        <input type="text" id='Phonenumber' name='Phonenumber' {...getFieldProps("Phonenumber")}
                            value={values.Phonenumber}
                            onChange={(e) => {
                                const input = e.target.value.trim().replace(/\D/g, ''); 
                                if (/^[0-9]*$/.test(input)) {
                                  setFieldValue("Phonenumber", input);
                                } else {
                                  setFieldValue("Phonenumber", ""); 
                                }
                              }}
                            onBlur={handleBlur} />
                        {touched.Phonenumber && errors.Phonenumber ? (
                            <div className="error_txt  d-flex align-items-center justify-content-center">{errors.Phonenumber}</div>
                        ) : null}</div>






                    <p className=' text-center'>WHAT ITEMS INTERESTED YOU THE
                        MOST ON OUR STALL?</p>
                    <p className='p'>(PLEASE SELECT AS MANY AS YOU LIKE)</p>

                    <div className=''>
                        <button  type="button" onClick={handleButtonClick5}  style={{ backgroundColor: isWhite5 ? '#ffffff' : '#beedf9', color: isWhite5 ? '#000000' : '#122b53' }} className='button2'>PLEASE SIGN ME UP TO THE NEWSLETTER AND KEEP ME INFORMED OF ANY SPECIALS BEBRANDED.COM.AU IS RUNNING.</button>

                        <button className='submitBtn' type={"submit"}
                            onClick={handleSubmit}>
                            SUBMIT ENTRY
                        </button>
                    </div>
                </form>

                <footer>
                    <div>
                        <a href="https://www.instagram.com/_bebranded/">
                            <img src="./assets/images//BB-2.png" alt="" />
                        </a>
                    </div>
                    <div>
                        <img src="./assets/images/BB-3.png" alt="footer-image" className=' footer-img' />
                        <p>Drawn Saturday 18/5/24, marquee includes full custom roof, Frame, carry bag and delivery to your school. all entries must complete the ENTRY form in full , LIKE BEBRANDED ON INSTAGRAM to be eligible.</p>
                    </div>
                </footer>
            </div >
        </div >
    );
}

export default Give_away;
