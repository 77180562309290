import { BrowserRouter, Routes, Route } from "react-router-dom";
import Give_away from "./Give_away";
import { useEffect } from "react";

const Routes1 = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<RedirectToBeBranded />} />
                <Route path="/giveaway" element={<Give_away />} />
            </Routes>
        </BrowserRouter>
    );
};

const RedirectToBeBranded = () => {
    useEffect(() => {
        window.location.href = "https://www.bebranded.com.au";
    }, []);

    return null; 
};

export default Routes1;
