import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes1 from './Routes';
 const App = ()=> {
  return (
    <>
    <Routes1/>

    </>
  )
}
export default App;