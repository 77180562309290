import axios from 'axios';
const BASE_URL = process.env.BASE_URL

export const add_giveaway = async (data) => {
    try {
        const response = await axios.post("https://api.bebranded.com.au//frontend/api/giveway/add/", data);
        //console.log(response);
        return response;
    } catch (error) {
        return error;
    }
};