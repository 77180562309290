import React from 'react';
import { Helmet } from 'react-helmet';

export const Halmet = ({ title, description, name, type })=> {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name='description' content={description} />}
      {type && (
        <>
          <meta property="og:type" content={type} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta name="twitter:card" content={type} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
        </>
      )}
      {name && <meta name="twitter:creator" content={name} />}
    </Helmet>
  );
}